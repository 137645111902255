import React from 'react'
import '../assets/css/style.css';
import { Container, Row, Col, Form, InputGroup, Card, CardGroup, FormGroup, ButtonGroup } from 'react-bootstrap';
import cloud from '../assets/img/cloud.jpg'
import hosting from '../assets/img/hosting.jpg'
import domain from '../assets/img/domain.jpg'
import payment from '../assets/img/payment.jpg'
import email from '../assets/img/email.jpg'
import information from '../assets/img/information.jpg'
import support from '../assets/img/support.jpg'
import firewall from '../assets/img/firewall.jpg'
const Courses = () => {
    return (
        <>

            <div className='servicesection'>
                <div className='blog'>
                    <div className="bannercontent my-auto align-items-center">
                        <h3 className="testimonialcontent pt-4  ">Our Services</h3>
                    </div>
                </div>
                <div className='servicvesbackground'>
                    <section className="service-grid pb-5 pt-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12 text-center mb-4">
                                    <div className="service-title">
                                        {/* <h4>Our Services</h4> */}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 text-center mb-3">
                                    <div className="service-wrap">
                                        <div class="service-icon">
                                            <img className=' mt-4 pb-4 img-fluid' src={hosting} loading='lazy' />
                                        </div>
                                        <h4>Web Hosting</h4>
                                        <p>Trust us to provide reliable and secure web hosting solutions that keep your website up and running smoothly.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 text-center mb-3">
                                    <div className="service-wrap">
                                        <div className="service-icon">
                                            <img className=' mt-4 pb-4 img-fluid' src={cloud} loading='lazy'  />
                                        </div>
                                        <h4>Cloud Servers</h4>
                                        <p>Explore the power of the cloud with our scalable and flexible cloud server options.</p>

                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 text-center mb-3">
                                    <div className="service-wrap">
                                        <div className="service-icon">
                                            <img className=' mt-4 pb-4 img-fluid' src={domain} loading='lazy'  />
                                        </div>
                                        <h4>Domain Registration</h4>
                                        <p>Secure your online identity with our hassle free domain registration services.
                                        </p>

                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 text-center mb-3">
                                    <div className="service-wrap">
                                        <div class="service-icon">
                                            <img className=' mt-4 pb-4 img-fluid' src={payment} loading='lazy'  />
                                        </div>
                                        <h4>Payment Gateways</h4>
                                        <p>Streamline your online transactions with our secure payment gateway solutions.</p>

                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 text-center mb-3">
                                    <div className="service-wrap">
                                        <div className="service-icon">
                                            <img className=' mt-4 pb-4 img-fluid' src={email} loading='lazy'  />
                                        </div>
                                        <h4>Gateway Services</h4>
                                        <p> Seamlessly connect with your customers through our SMS, WhatsApp, and email gateway services.</p>

                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 text-center mb-3">
                                    <div className="service-wrap">
                                        <div className="service-icon">
                                            <img className=' mt-4 pb-4 img-fluid' src={information} loading='lazy'  />
                                        </div>
                                        <h4>IMS Solutions</h4>
                                        <p>Enhance your communication and collaboration with our advanced IMS solutions.
                                        </p>

                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 text-center mb-3">
                                    <div className="service-wrap">
                                        <div className="service-icon">
                                            <img className=' mt-4 pb-4 img-fluid' src={support} loading='lazy'  />
                                        </div>
                                        <h4>Application Support</h4>
                                        <p>Ensure your applications run smoothly with our expert support services.
                                            
                                        </p>

                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 text-center mb-3">
                                    <div className="service-wrap">
                                        <div className="service-icon">
                                            <img className=' mt-4 pb-4 img-fluid' src={firewall} loading='lazy'  />
                                        </div>
                                        <h4>Firewall Setup/Maintenance</h4>
                                        <p>Protect your data and networks with our comprehensive firewall setup and maintenance services.
                                        </p>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>


                </div>
            </div>

        </>

    );
}

export default Courses;
import React, {Suspense, lazy} from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
 import Header from './Header';
 import Home from './Home';
 import About from './About';
 import Services from './Services';
 import Contact from './Contact';
 import Footer from './Footer'
function Layout() {

  return (

    <>
      <BrowserRouter>
    
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
       
      </BrowserRouter>




    </>
  )
}

export default Layout;
import React from 'react'
import '../assets/css/style.css';
import { Container, Row, Col, Form, InputGroup, Card, CardGroup, FormGroup, ButtonGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { faCloud } from '@fortawesome/free-solid-svg-icons'
import meet from '../assets/img/meet.png'
import expertise from '../assets/img/expertise.jpg'
const Home = () => {
    return (
        <>
            <div className='backgroundimage'>
                <Container>
                    <Row>
                        <Col sm={12} md={10} className=" text-light mx-auto text-center my-auto hometext">
                            <h1 className="business">Welcome to BMR Technologies! </h1>
                            <p className="banner-line-hight">
                                At BMR Technologies, we are dedicated to delivering a comprehensive suite of IT services
                                designed to elevate your business to new heights. Our mission is to build
                                the best IT services ecosystem for all industries,
                                ensuring seamless and efficient IT solutions tailored to your unique needs.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="aem-Grid aem-Grid--12 aem-Grid--default--12 ">
                <div className="plain-html base-component parbase aem-GridColumn aem-GridColumn--default--12">
                </div>
                <div className="plain-html base-component parbase aem-GridColumn aem-GridColumn--default--12">
                    <section className="rte-container" data-track-analytics="true">
                        <div className="enderSection">
                            <div className="enderSection-left-wrapper">
                                <div className="headingSection">About</div>
                                <div className="subheadingSection">BMR Technologies is a leading provider of IT solutions, committed to excellence and innovation. With a strong focus on delivering top-notch services, we have become a trusted partner for businesses across various industries.</div>
                                <div className="buttonsSection">
                                    <div className="leftCTAWrapper">
                                        <a id="enderFreeTrial"
                                            className="splunk-btn multiline sp-btn-solid sp-btn-pink none ga-cta standalone style splunkCTA"
                                            href="/en_us/download.html" data-track-analytics="true" target="_self" data-target="#"
                                            data-is-back-button="false" data-modalcontent-bookmarkable="false">

                                        </a>
                                    </div>
                                    <div className="rightCTAWrapper">
                                        <a id="enderContactSales"
                                            className="splunk-btn multiline sp-btn-hollow sp-btn-pink none ga-cta standalone style splunkCTA"
                                            href="/en_us/talk-to-sales.html?expertCode=sales" data-track-analytics="true"
                                            target="_self" data-target="#" data-is-back-button="false"
                                            data-modalcontent-bookmarkable="false">

                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="enderSection-right-wrapper">
                                <div className="circleGradientSection">
                                    <div className="gradient-element"></div>

                                </div>
                                <div className="mx-auto computerSection">
                                    <img className=' mt-4 pb-4 img-fluid' src={meet} loading='lazy' c />
                                </div>

                                <div className="smilingDudeSection">


                                </div>

                            </div>
                        </div>
                    </section>
                </div>

                <div className='servicesbackground'>

                    <div className="feat bg-gray pt-5 pb-5">
                        <div className="container">
                            <div className="row">
                                <div className=" mt-4 section-head col-sm-12">
                                    <h4>Our Services</h4>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="item"> <span class="icon feature_box_col_one"><i class="my-auto mx-2 fa fa-anchor"><FontAwesomeIcon icon={faGlobe} /></i></span>
                                        <h6>Web Hosting</h6>
                                        <p>Trust us to provide reliable and secure web hosting solutions that keep your website up and running smoothly.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="item"> <span class="icon feature_box_col_two"><i class="my-auto mx-1 fa fa-anchor"><FontAwesomeIcon icon={faCloud} /></i></span>
                                        <h6>Cloud Servers</h6>
                                        <p>Explore the power of the cloud with our scalable and flexible cloud server options.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="item"> <span class="icon feature_box_col_three"><i class="my-auto mx-2 fa fa-anchor"><FontAwesomeIcon icon={faGlobe} /></i></span>
                                        <h6>Domain Registration</h6>
                                        <p>Secure your online identity with our hassle-free domain registration services.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='homecolour'>
                        <Container className='py-5'>
                            <Row>

                                <Col className='my-auto column' sm={12} md={6} lg={6}>

                                    <h3 className='pt-4 mt-4 mobileapp'>Our Expertise</h3>
                                    <p className='my-auto mx-1 mb-3 expertiseCont'>
                                        At BMR Technologies, we take pride in our expertise, which spans across various domains. Our team is skilled in application support, ensuring your software functions flawlessly. Additionally, we excel in firewall setups and maintenance, keeping your systems secure.</p>

                                </Col>
                                <Col className=' my-auto' sm={12} md={6} lg={6}>
                                    <img className='img-fluid technology' src={expertise} />
                                </Col>
                            </Row>
                        </Container>


                    </div>

                </div>
            </div>

        </>
    );
}

export default Home;

import React from 'react'
import '../assets/css/style.css';
import { Container, Row, Col, ButtonGroup, FormGroup, InputGroup, FormControl, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faMapLocation } from '@fortawesome/free-solid-svg-icons'
function Contact() {
    return (
        <>
            <div className='contactsection'>
                <div className='contactbanner'>
                    <div className="bannercontent my-auto align-items-center">
                        <h4 className="testimonialcontent pt-4  ">Contact Us</h4>
                        <h5 className="mt-2 ms-3 d-none d-lg-block banner-line-hight logoariacontentelement">
                            Ready to elevate your business with our IT services? Reach out to us today!
                        </h5>

                    </div>
                </div>
            </div>

            <div className="contactcontainer">
                <div className=" subform">
                    <div className="contact-information">
                        <h3 className="title">Let's get in touch</h3>
                        <p className="contacttextcontent">

                            We look forward to hearing from you and
                            discussing how we can tailor our solutions to meet your specific needs.
                        </p>

                        <div className=" info">
                            <div className="information">
                                <i className="fa-solid fa-map-location-dot icon"></i>

                            </div>
                            <div class="single_footer single_footer_address">
                            <div className='footertsupport' >SUPPORT</div>
                            <h6 className='sales'> <a href="tel:+919843166444">+91 98431 66444</a></h6>
                            <h6 className='sales'><a href="mailto:istorecare@tagcs.in">istorecare@tagcs.in</a></h6>
                            
                           
                        </div>
                        <div class="single_footer single_footer_address">
                            <div className=' pt-2 footertsupport' >SALES</div>
                            <h6 className='sales'><a href="tel:+91 95859 40444">+91 95859 40444</a></h6>
                            <h6 className='sales'><a href="mailto:sales@tagcs.in">sales@tagcs.in</a></h6>
                            </div>
                            
                        </div>

                        {/* <div className="social-media">
                            <p>Connect with Us</p>
                            <div className="socialmedia-icons">
                                <a href="#"><i class="fab fa-facebook-f"><FontAwesomeIcon icon={faEnvelope} /></i></a>
                                <a href="#"><i class="fab fa-instagram"></i><FontAwesomeIcon icon={faPhone} /></a>

                                <a href="#"><i class="fab fa-linkedin-in"><FontAwesomeIcon icon={faMapLocation} /></i></a>
                            </div>
                        </div> */}
                    </div>
                    <div className="formone">


                        <form >
                            <h3 className=" mt-4 contacttitle">Contact Us</h3>
                            <div className="forminput-container">
                                <input type="text" placeholder="Name" className="inputtext" />

                            </div>
                            <div className="forminput-container">
                                <input type="text" placeholder="Email" className="inputtext" />
                            </div>
                            <div className="forminput-container">
                                <input type="text" placeholder="Phone" className="inputtext" />
                            </div>
                            <div className="forminput-container textarea">
                                <textarea name="message" placeholder="Message" className="inputtext"></textarea>

                            </div>
                            <div className='mb-4'>
                                <input type="submit" value="Send" className="sendbtn" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Contact;
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import bmrtech from '../assets/img/bmrtech.png';
import '../assets/css/style.css';

function Header() {
  const location = useLocation();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className="bg-body-tertiary"
      expanded={mobileMenuOpen}
    >
      <Container>
        <Navbar.Brand href="/">
          <img className='my-auto brandicon' src={bmrtech} alt="BMR Tech" />
        </Navbar.Brand>
        <Navbar.Toggle
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          aria-controls="responsive-navbar-nav"
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link
              as={Link}
              to="/"
              className={location.pathname === '/' ? 'active' : ''}
              onClick={closeMobileMenu} // Close the mobile menu when a link is clicked
            >
              Home
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/about"
              className={location.pathname === '/about' ? 'active' : ''}
              onClick={closeMobileMenu}
            >
              About
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/services"
              className={location.pathname === '/services' ? 'active' : ''}
              onClick={closeMobileMenu}
            >
              Services
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/contact"
              className={location.pathname === '/contact' ? 'active' : ''}
              onClick={closeMobileMenu}
            >
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;

import React from 'react'
import '../assets/css/style.css';
import { Container, Row, Col, InputGroup, Input } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faMapLocation } from '@fortawesome/free-solid-svg-icons'
const Footer = () => {
    return (
        <>
               
               {/* <div class="footer">
            <div class="container">     
                <div class="row">                       
                <div class="col-lg-3 col-md-6 col-sm-12">
                        
                        <div className="footer-text  pe-4 me-auto">                                        
                         <div className='bmrcontent'> BMR TECHNOLOGIES</div>
                                    </div>
                                    <div className='footertext mb-3 mt-4'>We are also specialized in digital marketing, helping 
                                    you harness the power of online marketing strategies to grow your business.
                                 

                            
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <div class="single_footer single_footer_address">
                            <h4 className=''>Useful Link</h4>
                            <ul>
                                <li><a href="about">About</a></li>
                                <li><a href="services">Services</a></li>
                                <li><a href="contact">Contact </a></li>
                                
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <div class="single_footer single_footer_address">
                            <h4 >Support</h4>
                            <h6 > <a className='support' href="tel:+919843166444">+91 98431 66444</a></h6>
                            <h6><a className='support' href="mailto:istorecare@tagcs.in">istorecare@tagcs.in</a></h6>
                            
                           
                        </div>
                    </div>

<div class="col-lg-3 col-md-6 col-sm-12">
                        <div class="single_footer single_footer_address">
                            <h4>Sales</h4>
                            <h6 > <a className='support' href="tel:+91 95859 40444">+91 95859 40444</a></h6>
                            <h6><a className='support' href="mailto:sales@tagcs.in">sales@tagcs.in</a></h6>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-sm-12 col-xs-12">
                        <p class="copyright">Copyrights © 2023 BMR Technologies. All Rights Reserved. Design & Developed By <a href="https://tagcs.in/" target='_blank'>TAGCS</a>.</p>
                    </div>               
                </div>             
            </div>
        </div> */}
        <section class="footer_sec">
            <div class="pt-4 container">
                <div class="row">
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <div class="footer_align">
                            <h6>BMR TECHNOLOGIES</h6>
                            <ul class="list-unstyled">
                                <li class="">
                                We are also specialized in digital marketing, helping you harness the power of online marketing strategies to grow your business.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <div class="footer_align">
                            <h6>USEFUL LINK</h6>
                            <ul class="list-unstyled">
                                <li class="">
                                    <a href="/about">About</a>
                                </li>
                                <li class="">
                                    <a href="/services">Services</a>
                                </li>
                                <li class="">
                                    <a href="/contact">Contact</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <div class="footer_align">
                            <h6>SUPPORT</h6>
                            <ul class="list-unstyled">
                                <li class="address_align">
                                    <a href="tel:+919843166444">+91 98431 66444</a>
                                </li>
                                <li class="address_align">
                                    <a href="mailto:istorecare@tagcs.in">istorecare@tagcs.in</a>
                                </li>
                            </ul>
                            <h6> SALES</h6>
                            <ul class="list-unstyled">
                                <li class="address_align">
                                    <a href="tel:+919585940444">+91 95859 40444</a>
                                </li>
                                <li class="address_align">
                                    <a href="mailto:sales@tagcs.in">sales@tagcs.in</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <div class="footer_align">
                                    <ul class="list-unstyled d-inline-flex pt-0">
                                        <li class="foot_icon ps-3">
                                            <a target="blank" href="https://www.facebook.com/TAGTECHNOLOGYSERVICES/">
                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"></path>
                                                </svg>
                                            </a>
                                        </li>
                                        <li class="foot_icon ps-3">
                                            <a target="blank" href="https://twitter.com/TAGTES_groups">
                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"></path>
                                                </svg>
                                            </a>
                                        </li>
                                        <li class="foot_icon ps-3">
                                            <a target="blank" href="https://www.youtube.com/channel/UCslrKYViEkB8kWdP9HaxHaQ">
                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z"></path>
                                                </svg>
                                            </a>
                                        </li>
                                        <li class="foot_icon ps-3">
                                            <a target="blank" href="https://www.linkedin.com/company/tagtes/">
                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"></path>
                                                </svg>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div class="row">
                            <div class="col-lg-6 col-md-12 col-sm-12">
                                <div class="copyright">
                                    <p class="text-center">Copyrights © 2023 BMR Technologies. All Rights Reserved.</p>
                                </div>
                            </div>
                        <div class="col-lg-6 col-md-12 col-sm-12">
                            <div class="bytagtes">
                                <p class="text-center">Design &amp; Developed By <span><a  target="blank"  href="https://tagcs.in/">TAGCS.</a></span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}
export default Footer;

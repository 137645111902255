import React from 'react'
import '../assets/css/style.css';
import { Container, Row, Col, ButtonGroup } from 'react-bootstrap';
import technology from '../assets/img/technology.jpg'
function About() {
    return (
        <>

            <div className='contactsection'>
                <div className='innerbanner'>
                    <div className="bannercontent my-auto align-items-center">
                        <h4 className="testimonialcontent pt-4  ">About </h4>

                    </div>
                </div>
            </div>

            <div className='contentsectionbanner'>

                <div className=''>
                    <Container>
                        <Row>
                            <Col className=' my-auto abtcontent' sm={12} md={6} lg={6}>
                                <div className="bannercontent my-auto align-items-center">
                                </div>
                                <p className='mt-4 pt-4'>
                                BMR Technologies is a leading provider of IT solutions, committed to excellence and innovation. With a strong focus on delivering top-notch services, we have become a trusted partner for businesses across various industries.
                                </p>
                                <p className='mt-4 pt-4'>
                                Our offerings include web hosting, cloud servers, domain registration, and more. We also specialize in SMS/WhatsApp/email gateway services, payment gateways, IMS solutions, and application support and firewall setups/maintenance.
                                </p>
                                <p className='mt-4 pt-4'>
                                Our expertise extends to digital marketing services, ensuring your online presence is not only strong but also strategically positioned to reach your target audience effectively.
                                </p>
                            </Col>
                            <Col className=' my-auto' sm={12} md={6} lg={6}>
                                <img className=' mt-4 pb-4 img-fluid technology' src={technology} loading='lazy'  />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>





        </>
    );
}
export default About;